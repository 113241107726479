// This file was automatically generated from manager.manage.membershipgroup.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.MembershipGroupEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.MembershipGroupEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.MembershipGroupEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'membershipgroups'}), output);
  output.append('<div id="page-membership-groups-edit" class="has-sidebar"><div id="main-area"><div id="content-header"><h2><a href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/membershipgroups">MEMBERSHIP GROUPS</a>/', (opt_data.content.group_id) ? soy.$$escapeHtml(opt_data.content.group.name) : 'NEW MEMBERSHIP GROUP', '</h2></div>', (opt_data.content.message) ? '<div class="global-message success fade">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', '<form id="membership-edit" class="styled bare" method="', (opt_data.content.group_id) ? 'put' : 'post', '" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/membershipgroups', (opt_data.content.group_id) ? '/' + soy.$$escapeHtml(opt_data.content.group_id) : '', '" sr-validate ><h3>Membership details</h3>');
  Nightloop.Base.FormElement({name: 'name', label: 'Membership group name', type: 'text', value: opt_data.content.group.name, validator_type: 'not_empty', validator_message: 'Please enter a group name'}, output);
  output.append('<div class="inline">');
  Nightloop.Base.FormElement({name: 'code', label: 'Group code', type: 'text', value: opt_data.content.group.code, maxlength: '8', classes: 'code-entry', validator_type: 'not_empty', validator_message: 'Please enter a group code'}, output);
  output.append('<div class="form-element color-picker"><p class="label">Color</p><div id="colorpicker"><div class="input-area input-area-colorpicker" style="position:relative"><div type="text" style="width:50px; height:50px; position: absolute; z-index: 11; display: block;"></div><input type="text" class="form-element text color input-colorpicker" disabled="disabled" /><input name="color" type="hidden" class="input-colorpicker-val" value="', (opt_data.content.group.color) ? soy.$$escapeHtml(opt_data.content.group.color) : '#87CEFA', '" /><div class="colorpicker-container no-display"><div class=\'colorpicker\'></div></div></div></div></div></div><input type="hidden" name="is_global" value="0" /><div class="vip radioset inline"><p class="group-label">Is this group VIP?</p>');
  Nightloop.Base.FormElement({id: 'vip_yes', label: 'Yes', name: 'is_vip', value: '1', type: 'radio', parentclass: 'first', checkit: (opt_data.content.group.vip) ? '1' : ''}, output);
  Nightloop.Base.FormElement({id: 'vip_no', label: 'No', name: 'is_vip', value: '0', type: 'radio', parentclass: 'last', checkit: (! opt_data.content.group.vip) ? '0' : ''}, output);
  output.append('</div><h3>Payment options</h3><div class="standard-list input-table"><div class="table-header"><div class="col col-term-header">Term period</div><div class="col col-billing-header">Billing period</div><div class="col col-payment">Payment</div><div class="col col-action"></div></div><div class="list-block" id="payment-plans">');
  if (opt_data.content.plans) {
    var planList8943 = opt_data.content.plans;
    var planListLen8943 = planList8943.length;
    for (var planIndex8943 = 0; planIndex8943 < planListLen8943; planIndex8943++) {
      var planData8943 = planList8943[planIndex8943];
      Nightloop.Templates.Manager.Manage.PaymentPlan(soy.$$augmentData(opt_data, {plan: planData8943, first: false}), output);
    }
  } else {
    Nightloop.Templates.Manager.Manage.PaymentPlan(soy.$$augmentData(opt_data, {first: true}), output);
  }
  output.append('<div id="append-plans"></div></div></div><p class="add-more"><span>+</span> <a id="add-payment-plan" href="#AddOption">Add option</a></p></form></div><div id="fixed-action-panel"><p class="button"><a id="payouts-save" class="sendform" href="#Save" target="#membership-edit" success_redirect="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/membershipgroups" >Save</a></p></div></div><script type="text/javascript">$(document).ready(function() {MembershipGroupEdit.init();});<\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.PaymentPlan = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="input-row plan-row"><input type="hidden" name="plan_id" class="plan-id" value="', (opt_data.plan) ? soy.$$escapeHtml(opt_data.plan.id) : '', '" /><div class="col col-term">');
  Nightloop.Base.FormElement({name: 'term_frequency', parentclass: 'term-frequency', validator_type: 'int_positive', validator_message: 'Invalid term', value: (opt_data.plan) ? soy.$$escapeHtml(opt_data.plan.term_frequency) : ''}, output);
  output.append('</div><div class="col col-term-unit"><div class="form-element select term-unit"><label for="term_unit">');
  Nightloop.Templates.Manager.Manage.PeriodSelector({name: 'term_unit', value: (opt_data.plan) ? soy.$$escapeHtml(opt_data.plan.term_unit) : ''}, output);
  output.append('</label></div></div><div class="col col-billing">');
  Nightloop.Base.FormElement({name: 'billing_frequency', parentclass: 'billing-frequency', validator_type: 'int_positive', validator_message: 'Invalid frequency', value: (opt_data.plan) ? soy.$$escapeHtml(opt_data.plan.billing_frequency) : ''}, output);
  output.append('</div><div class="col col-frequency"><div class="form-element select frequency"><label for="frequency">');
  Nightloop.Templates.Manager.Manage.PeriodSelector({name: 'billing_unit', value: (opt_data.plan) ? soy.$$escapeHtml(opt_data.plan.billing_unit) : ''}, output);
  output.append('</label></div></div><div class="col col-payment">');
  Nightloop.Base.FormElement({name: 'payment', parentclass: 'payment', validator_type: 'price', validator_message: 'Invalid amount', value: (opt_data.plan) ? soy.$$escapeHtml(opt_data.plan.payment_formatted) : ''}, output);
  output.append('</div>', (! opt_data.first) ? '<div class="col col-action"><a class="closer" href="javascript:void(0);">&times;</a></div>' : '', '</div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.PeriodSelector = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<p class="input"><select name="', soy.$$escapeHtml(opt_data.name), '"><option ', (opt_data.value == 'WEEK') ? 'selected' : '', ' value="WEEK">Weeks</option><option ', (opt_data.value == 'MONTH') ? 'selected' : '', ' value="MONTH">Months</option><option ', (opt_data.value == 'YEAR') ? 'selected' : '', ' value="YEAR">Years</option></select><span class="downer"></span></p>');
  return opt_sb ? '' : output.toString();
};
